import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN, REFRESH_INTERNAL_DATA_API } from '../systems/configurations';
import beautify from 'json-beautify';
import moment from 'moment';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const Monitoring: ModuleConfigModel = {
  moduleName: 'Monitoring',
  layout: 'DEFAULT',
  path: 'Log',
  renderComponent: ApiCaller,
  headerFunctions: [
    {
      name: 'Refresh Internal Data',
      postUrl: REFRESH_INTERNAL_DATA_API,
      successMessage: 'Refresh internal data successfully',
      failedMessage: 'Refresh internal data failed',
      primary: true,
      confirmation: {
        confirmMessage: 'Are you sure to refresh internal data?',
        okText: 'Refresh',
        cancelText: 'Cancel',
      },
    },
  ],
  get: {
    url: API_DOMAIN + '/logs?$limit=50',
    fields: [
      {
        field: 'timestamp',
        name: 'Date/Time',
        width: 180,
        formatFunc: (timestamp: Date) => {
          return moment(timestamp).format('DD MMM YYYY, HH:mm:ss');
        },
      },
      {
        field: 'level',
        name: 'Level',
        width: 100,
      },
      {
        field: 'message',
        name: 'Message',
      },
      {
        field: 'source',
        dataKey: 'meta',
        name: 'source',
        formatFunc: (meta: any) => {
          if (!meta || !meta.source) {
            return '';
          }

          return beautify(meta.source, null, 40);
        },
      },
      {
        field: 'data',
        dataKey: 'meta',
        name: 'data',
        formatFunc: (meta: any) => {
          if (!meta || !meta.data) {
            return '';
          }

          return beautify(meta.data, null, 40);
        },
      },
    ],
  },
};
