import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const Summary: ModuleConfigModel = {
  moduleName: 'Summary',
  layout: 'SUMMARY',
  path: 'summary',
  renderComponent: ApiCaller,
  get: {
    url: API_DOMAIN + '/summary',
    fields: [
      {
        field: 'sourceSystemCode',
        name: 'Source System Code',
      },
      {
        field: 'sourceSystemModuleCode',
        name: 'Source System Module Code',
      },
      {
        field: 'targetSystemCode',
        name: 'Target System Code',
      },
      {
        field: 'targetSystemModuleCode',
        name: 'Target System Module Code',
      },
      {
        field: 'pairedKeyCount',
        name: 'Number of Paired Keys',
      },
      {
        field: 'noPairKeyCount',
        name: 'Number of No Pair Keys',
      },
    ],
  },
  post: {
    url: API_DOMAIN + '/synchronizer',
  },
};
