import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const SystemCredentials: ModuleConfigModel = {
  moduleName: 'System Credentials',
  layout: 'DEFAULT',
  path: 'systemCredentials',
  renderComponent: ApiCaller,
  sampleData: [
    {
      title: 'Use simple API keys',
      data: `
      {
        "systemCode": "DATAALT",
        "type": "APIKEY",
        "credentialInfo": {
          "accessToken": {
            "token": "07d966ff-4dec-4153-88d5-c908587720ed"
          }
        }
      }`,
    },
    {
      title: 'Authentication via Auth0',
      data: `
      {
        "systemCode": "HIVEGRID",
        "type": "AUTH0",
        "credentialInfo": {
          "request": {
            "url": "https://my-domain.auth0.com/oauth/token",
            "clientId": "12345678",
            "clientSecret": "this_is_a_secret",
            "audience": "https://hivegrid-api",
            "grantType": "client_credentials"
          },
          "accessExtraInfo": {
            "internal": "internal-api-key"
          }
        }
      }`,
    },
    {
      title: 'Basic Authentication',
      data: `
      {
        "systemCode": "HIVEGRID",
        "type": "AUTH0",
        "credentialInfo": {
          "authorization": "Basic aeiou"
        }
      }`,
    },
  ],
  post: {
    url: API_DOMAIN + '/credentials',
  },
};
