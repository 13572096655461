import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const SystemModuleApis: ModuleConfigModel = {
  moduleName: 'System Module APIs',
  layout: 'DEFAULT',
  path: 'systemModuleAPIs',
  renderComponent: ApiCaller,
  postSampleData: {
    systemCode: '',
    systemModuleCode: '',
    post: '',
    getOne: '',
    find: '',
    patch: '',
  },
  post: {
    url: API_DOMAIN + '/systemModuleApis',
  },
  patch: {
    url: API_DOMAIN + '/systemModuleApis',
  },
  get: {
    url: API_DOMAIN + '/systemModuleApis',
    fields: [
      {
        field: 'systemCode',
        name: 'System Code',
      },
      {
        field: 'systemModuleCode',
        name: 'System Module Code',
      },
      {
        field: 'post',
        name: 'POST',
      },
      {
        field: 'getOne',
        name: 'GET',
      },
      {
        field: 'find',
        name: 'FIND',
      },
      {
        field: 'patch',
        name: 'PATCH',
      },
    ],
  },
};
