import { Systems } from './Systems';
import { SystemModules } from './SystemModules';
import { SystemModuleApis } from './SystemModuleApis';
import { DataAdapterSystemMaps } from './DataAdapterSystemMaps';
import { SystemCredentials } from './SystemCredentials';
import { DataTransformers } from './DataTransformers';
import { ScriptEditors } from './ScriptEditors';
import { OperationTriggerFlows } from './OperationTriggerFlows';
import { Monitoring } from './Monitoring';
import { Summary } from './Summary';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const moduleConfigs: Array<ModuleConfigModel> = [
  Summary,
  Systems,
  SystemModules,
  SystemModuleApis,
  DataAdapterSystemMaps,
  SystemCredentials,
  DataTransformers,
  OperationTriggerFlows,
  ScriptEditors,
  Monitoring,
];
