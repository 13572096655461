
import axios from "axios";
import jsec from "jsesc";
import beautify from "json-beautify";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import RearrangeableFlowChartWithControl from "../components/RearrangeableFlowChartWithControl.vue";
import RearrangeableFlowChart from "../components/RearrangeableFlowChart.vue";
import { ModuleConfigModel, moduleConfigs } from "../configs/ApiConfig";
import CodeEditor from "./CodeEditor.vue";
import Synchronizer from "./Synchronizer.vue";

import { NODE_ENV } from "../systems/configurations";

const postCollapseName: string = "post-collapse";
const patchCollapseName: string = "patch-collapse";

@Component({
  components: {
    RearrangeableFlowChartWithControl,
    RearrangeableFlowChart,
    CodeEditor,
    Synchronizer,
  },
})
export default class ApiCaller extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;
    if (NODE_ENV !== "development") {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        this.$notification["error"]({
          message: err.message,
          description: "",
          placement: "bottomRight",
        });
      }
    }

    return accessToken;
  }
  //#endregion

  //#region constants
  DEFAULT_LAYOUT = "DEFAULT";
  SUMMARY_LAYTOUT = "SUMMARY";
  FLOW_LAYOUT = "FLOW";
  //#endregion

  isAddFlowModalShown = false;
  addFlowType = "";
  addFlowArgs = "";
  addStepDesc = "";

  isEditFlowModalShown = false;
  editStepDesc = "";
  editStepScript: string | undefined = "";
  editFlowType: string = "";
  editFlowArgs: any = null;
  editingNode: any = null;

  @Prop()
  module: any;

  @Watch("module")
  onModuleChanged() {
    this.initialApiCaller();
  }

  beforeMount() {
    this.initialApiCaller();
  }

  initialApiCaller() {
    this.selectedModule = this.module;
    this.selectedModuleSampleData = this.module.sampleData;
    this.selectedModulePostSampleData = this.module.postSampleData;
    this.postBody = JSON.stringify({});
    this.patchBody = JSON.stringify({});

    this.tableRowSelectedIndex = -1;

    this.setDataTableColumns();

    if (this.selectedModule && this.selectedModule.get) {
      this.callGetAPI();
    }
  }

  selectedModule: ModuleConfigModel = moduleConfigs[0];
  selectedModuleSampleData: any = null;
  selectedModulePostSampleData: any = null;

  stringEscapePost: string = "";
  stringEscapePatch: string = "";

  postBody: string = JSON.stringify({});
  postScriptBody: string = "";

  updatePostScriptBody() {
    try {
      const postBodyObject = JSON.parse(this.postBody);

      if (this.selectedModule?.post?.scriptVariableName) {
        this.postScriptBody =
          postBodyObject[this.selectedModule.post.scriptVariableName];
      }
    } catch (err) {}
  }

  @Watch("postScriptBody")
  onPostScriptBodyChanged(val: any) {
    let postObject = JSON.parse(this.postBody);

    if (this.selectedModule?.post?.scriptVariableName) {
      postObject[
        this.selectedModule.post.scriptVariableName
      ] = this.postScriptBody;

      this.postBody = this.formatJSON(JSON.stringify(postObject));
    }
  }

  patchBody: string = JSON.stringify({});
  patchScriptBody: string = "";

  updatePatchScriptBody() {
    try {
      const patchBodyObject = JSON.parse(this.patchBody);

      if (this.selectedModule?.patch?.scriptVariableName) {
        this.patchScriptBody =
          patchBodyObject[this.selectedModule.patch.scriptVariableName];
      }
    } catch (err) {}
  }

  @Watch("patchScriptBody")
  onPatchScriptBodyChanged(val: any) {
    let patchObject = JSON.parse(this.patchBody);

    if (this.selectedModule?.patch?.scriptVariableName) {
      patchObject[
        this.selectedModule.patch.scriptVariableName
      ] = this.patchScriptBody;

      this.patchBody = this.formatJSON(JSON.stringify(patchObject));
    }
  }

  moduleData: any = null;
  moduleSampleData: any = null;
  getResData: any = null;
  columns: any[] = [];
  tableRowSelectedIndex = -1;

  selectedRow: any = -1;
  selectedRowData: any = null;

  collapseActives: any = [];

  customRow(record: any, index: any) {
    return {
      rowClassName: (record: any, index: any) => {
        if (this.selectedRow == index) {
          console.log(index);
        }
      },
      on: {
        click: () => {
          this.selectedRowData = record;
          try {

            const data = this.getResData.find((d: any) => {
              return d._id.toString() === record._id.toString();
            });
            delete data.key;
            delete data.createdDate;
            delete data.updatedDate;
            delete data.__v;

            try {
              this.patchBody = beautify(data, null, 4, 80);
              this.updatePatchScriptBody();

              this.tableRowSelectedIndex = index;
              this.openCollapse(patchCollapseName);
            } catch (err) {
              console.log(err);
            }
          } catch (err) {}

        },
      },
    };
  }

  openCollapse(collapseName: string) {
    if (this.collapseActives.indexOf(collapseName) >= 0) {
      this.collapseActives.splice(this.collapseActives.indexOf(collapseName), 1);
    }
    this.collapseActives.push(collapseName);
  }

  setDataTableColumns() {
    this.columns = [];

    if (!this.selectedModule || !this.selectedModule.get || !this.selectedModule.get.fields) {
      return;
    }

    for (let i = 0; i < this.selectedModule.get.fields.length; i++) {
      let field = this.selectedModule.get.fields[i];
      this.columns.push({
        title: field.name,
        dataIndex: field.field,
        width: field.width ? field.width : '',
        sorter: (a: any, b: any) => a[field.field].localeCompare(b[field.field]),
      });
    }
  }

  formatJSON(jsonString: string) {
    let json = JSON.parse(jsonString);
    return beautify(json, null, 4, 80);
  }

  onGetCodeSampleClicked(e: any) {
    if (this.selectedModulePostSampleData) {
      this.postBody = this.formatJSON(JSON.stringify(this.selectedModulePostSampleData));
    }
    e.stopPropagation();
  }

  onStringEscapePostClicked() {
    this.stringEscapePost = jsec(this.stringEscapePost, {
      json: true,
    });
  }

  onStringEscapePatchClicked() {
    this.stringEscapePatch = jsec(this.stringEscapePatch, {
      json: true,
    });
  }

  onPostBodyBlur() {
    this.postBody = this.formatJSON(this.postBody);
    this.updatePostScriptBody();
  }

  onPatchBodyBlur() {
    this.patchBody = this.formatJSON(this.patchBody);
    this.updatePatchScriptBody();
  }

  getKeyIndex(data: any) {
    for (let i = 0; i < data.length; i++) {
      data[i]["key"] = i;
    }
    return data;
  }

  formatData(value: any, formatFunc: Function | undefined) {
    if (formatFunc) {
      return formatFunc(value);
    }

    if (value instanceof Object || value instanceof Array) {
      return JSON.stringify(value);
    }

    return value;
  }

  serializeDataTableObject(data: any[]) {
    let returnData: any[] = [];

    for (let i = 0; i < data.length; i++) {
      let temp = Object.assign({}, data[i]);
      if (!this.selectedModule || !this.selectedModule.get || !this.selectedModule.get.fields) {
        return;
      }

      for (let j = 0; j < this.selectedModule.get.fields.length; j++) {
        let field: string = this.selectedModule.get.fields[j].field;
        const formatFunc = this.selectedModule.get.fields[j].formatFunc;

        let dataKey = '';
        
        if (this.selectedModule.get.fields[j].dataKey) {
          dataKey = this.selectedModule.get.fields[j].dataKey ?? '';
        }
        else {
          dataKey = this.selectedModule.get.fields[j].field;
        }
        
        temp[field] = this.formatData(data[i][dataKey], formatFunc);
      }

      returnData.push(temp);
    }
    return returnData;
  }

  async callGetAPI() {
    if (!this.selectedModule.get || !this.selectedModule.get.url) {
      return;
    }

    this.getResData = [];
    this.moduleData = [];

    const accessToken = await this.getAccessToken();

    try {
      const res = await axios.get(
        this.selectedModule.get.url,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );
      if (res.data && res.data.length) {
        this.getResData = this.getKeyIndex(res.data);
        this.moduleData = this.serializeDataTableObject(this.getResData);
      }
    } catch (err) {
      this.$notification["error"]({
        message: err.message,
        description: "",
        placement: "bottomRight",
      });
    }
  }

  callPostAPI() {
    if (!this.selectedModule || !this.selectedModule.post) {
      return;
    }

    this.callPostAPICore(
      this.selectedModule.post.url,
      JSON.parse(this.postBody),
      "Created data successfully",
      "Creating data failed"
    );
  }

  async callPostAPICore(url: string, postbody: any, successMessage: string, errorMessage: string) {
    const accessToken = await this.getAccessToken();

    try {
      const res = await axios.post(
        url,
        postbody,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );

      this.callGetAPI();
      this.$notification["success"]({
        message: successMessage,
        description: "",
        placement: "bottomRight",
      });
    } catch (err) {
      this.$notification["error"]({
        message: errorMessage,
        description: err.response.data.errorMessage ?? err.message,
        placement: "bottomRight",
      });
    }
  }

  async callPatchAPI() {
    const accessToken = await this.getAccessToken();

    try {
      if (!this.selectedModule || !this.selectedModule.patch) {
        return;
      }

      const res = await axios.patch(
        this.selectedModule.patch.url,
        JSON.parse(this.patchBody),
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );

      this.callGetAPI();
      this.$notification["success"]({
        message: "Updated data successfully",
        description: "",
        placement: "bottomRight",
      });
    } catch (err) {
      this.$notification["error"]({
        message: "Updating data failed",
        description: err.response.data.errorMessage ?? err.message,
        placement: "bottomRight",
      });
    }
  }

  @Watch("openKeys")
  onOpenKeysChanged(val: any) {
    console.log("openKeys", val);
  }
}
