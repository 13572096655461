import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const Systems: ModuleConfigModel = {
  moduleName: 'Systems',
  layout: 'DEFAULT',
  path: 'systems',
  renderComponent: ApiCaller,
  postSampleData: {
    code: '',
    name: '',
  },
  post: {
    url: API_DOMAIN + '/systems',
  },
  get: {
    url: API_DOMAIN + '/systems',
    fields: [
      {
        field: 'code',
        name: 'Code',
      },
      {
        field: 'name',
        name: 'Name',
      },
    ],
  },
};
