import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { dateFormat } from './Functions';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const OperationTriggerFlows: ModuleConfigModel = {
  moduleName: 'Operation Trigger Flow',
  layout: 'FLOW',
  path: 'operationTriggerFlow',
  renderComponent: ApiCaller,
  get: {
    url: API_DOMAIN + '/operationTriggerFlows',
    fields: [
      {
        field: 'description',
        name: 'Description',
      },
      {
        field: 'createdDate',
        name: 'Created Date',
        formatFunc: dateFormat,
      },
      {
        field: 'updatedDate',
        name: 'Updated Date',
        formatFunc: dateFormat,
      },
    ],
  },
  post: {
    url: API_DOMAIN + '/operationTriggerFlows',
    showStringEscape: false,
  },
  patch: {
    url: API_DOMAIN + '/operationTriggerFlows',
    showStringEscape: false,
  },
  extraGetApi: [
    {
      url: API_DOMAIN + '/scripts',
    },
  ],
  flowUI: {
    information: [
      {
        header: 'Initial data context',
        content: `The initial step has an extra field of long running task status named $operationStatus, for example
{
  _id: 5f7ab9522287534944fcf7c8,
  clientId: 5f7ab9522287534944fcf7c9,
  ...
  $operationStatus: 'SUCCESS' or 'ERROR'
}
`,
      },
      {
        header: 'Step type list',
        content: `
type: SPREADS
args: { fieldName }

type: CONDITIONAL
args: { scriptContent }

type: ACTIVATEHOOK
args: { payloadType: e.g. DATAANALYTICS.RESULTS.CREATED }

type: POST/PATCH
args: { systemCode, systemModuleCode, 
        scriptContent: "pre-execute script" }

type: GET
args: { systemCode, systemModuleCode, 
        scriptContent: "post-execute script" }

type: FIND
args: { systemCode, systemModuleCode, 
        scriptContent: "post-execute script" }
Note: Create prior execute step to construct datacontext.params as find query parameters

type: EXECUTESCRIPT 
args: { scriptContent }`,
      },
    ],
    nameBy: 'type',
    sortBy: 'order',
    stepBy: 'steps',
  },
};
