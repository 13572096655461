import ApiCaller from "../components/ApiCaller.vue";
import {API_DOMAIN} from "../systems/configurations";
import { ModuleConfigModel } from "./interfaces/IApiConfig";

export const DataTransformers: ModuleConfigModel = {
  moduleName: "Data Transformers",
  layout: "DEFAULT",
  path: "dataTransformers",
  renderComponent: ApiCaller,
  postSampleData: {
    sourceSystemCode: "",
    sourceSystemModuleCode: "",
    targetSystemCode: "",
    targetSystemModuleCode: "",
    operationCode: "CREATED/UPDATED",
    type: "PRE/POST",
    sourceOperationCondition: "",
    content: "Data transformation Javascript function"
  },
  get: {
    url: API_DOMAIN + "/transformers",
    fields: [
      {
        field: "sourceSystemCode",
        name: "Source System"
      }, {
        field: "sourceSystemModuleCode",
        name: "Source System Module"
      }, {
        field: "targetSystemCode",
        name: "Target System"
      }, {
        field: "targetSystemModuleCode",
        name: "Target System Module"
      }, {
        field: "operationCode",
        name: "Operation"
      }, {
        field: "type",
        name: "Type"
      }, {
        field: "sourceOperationCondition",
        name: "Condition"
      }, {
        field: "createdDate",
        name: "Created Date"
      }, {
        field: "updatedDate",
        name: "UpdatedDate"
      }
    ]
  },
  post: {
    url: API_DOMAIN + "/transformers",
    showStringEscape: true,
    scriptVariableName: "content"
  },
  patch: {
    url: API_DOMAIN + "/transformers",
    showStringEscape: true,
    scriptVariableName: "content"
  }
};
