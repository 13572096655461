import ScriptEditor from '../components/ScriptEditor.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const ScriptEditors: ModuleConfigModel = {
  moduleName: 'Script Editor',
  layout: 'DEFAULT',
  path: 'scriptEditor',
  renderComponent: ScriptEditor,
  get: {
    url: API_DOMAIN + '/scripts',
  },
  patch: {
    url: API_DOMAIN + '/scripts',
  },
};
