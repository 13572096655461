
import { Vue, Component, Prop } from "vue-property-decorator";
import CodeEditor from "./CodeEditor.vue";
import { NODE_ENV } from "../systems/configurations";

import axios from "axios";

@Component({
  components: { CodeEditor },
})
export default class ScriptEditor extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;

    if (NODE_ENV !== "development") {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        this.$notification["error"]({
          message: err.message,
          description: "",
          placement: "bottomRight",
        });
      }
    }

    return accessToken;
  }
  //#endregion

  collapsed: boolean = false;
  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  code: string = "";

  selectedScript: any = null;
  scripts: any[] = [];

  @Prop()
  module: any;

  openNotification(message: any) {
    this.$notification["success"]({
      message: message,
      description: "",
      placement: "bottomRight",
    });
  }

  async updateScript() {
    if (!this.selectedScript) {
      this.$notification["warning"]({
        message: "There is no a selected script.",
        description: "",
        placement: "bottomRight",
      });
      return;
    }

    let updateData = {
      _id: this.selectedScript._id,
      name: this.selectedScript.name,
      content: this.code,
      isActive: true,
    };

    const accessToken = await this.getAccessToken();

    try {
      let res = await axios.patch(
        this.module.patch.url,
        updateData,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );

      this.$notification["success"]({
        message: "Update Completed.",
        description: "",
        placement: "bottomRight",
      });
      this.fetchScripts();
    } catch (err) {
      this.$notification["error"]({
        message: err.message,
        description: "",
        placement: "bottomRight",
      });
    }
  }

  onItemClicked(data: any) {
    this.selectedScript = data;
    this.code = this.selectedScript.content;
  }

  async fetchScripts() {
    const accessToken = await this.getAccessToken();

    try {
      let res = await axios.get(
        this.module.get.url,
        accessToken
          ? {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          : undefined
      );
      this.scripts = res.data;
    } catch (err) {
      this.$notification["error"]({
        message: err.message,
        description: "",
        placement: "bottomRight",
      });
    }
  }

  mounted() {
    this.fetchScripts();
  }
}
