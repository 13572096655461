import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import MenuBar from "../components/MenuBar.vue";

import {moduleConfigs} from "../configs/ApiConfig";

import {authGuard} from "../auth/authGuard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [ {
    path: "/",
    component: MenuBar,
    children: moduleConfigs.map((data) => {
      let child = {
        path: data.path,
        name: data.path,
        component: data.renderComponent,
        props: {
          module: data
        }
      };

      return child;
    }),
    beforeEnter: process.env.VUE_APP_NODE_ENV !== "development"
      ? authGuard
      : undefined
  }
];

const router = new VueRouter({mode: "history", base: process.env.BASE_URL, routes});

export default router;
