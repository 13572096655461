import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

import {Auth0Plugin} from "./auth";

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const cacheLocation: string = "localstorage";

console.log(domain)

Vue.use(Antd);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  cacheLocation,
  onRedirectCallback: (appState : any) => {
    router.push(
      appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname);
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
