
import { Vue, Component } from 'vue-property-decorator';

import { moduleConfigs } from '../configs/ApiConfig';

const moduleMenuName: string = 'module';

@Component
export default class MenuBar extends Vue {
  modules: any = moduleConfigs;
  openKeys: any = [moduleMenuName];

  selectedModule: any = null;
  selectedModuleSampleData: any = null;
  selectedModulePostSampleData: any = null;

  postBody: string = JSON.stringify({});
  patchBody: string = JSON.stringify({});

  version: string = process.env.VUE_APP_VERSION;

  handleSelectedModuleChanged(e: any) {
    this.selectedModule = this.modules[e.key];
    this.selectedModuleSampleData = this.modules[e.key].sampleData;
    this.selectedModulePostSampleData = this.modules[e.key].postSampleData;
  }
}
