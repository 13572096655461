import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const DataAdapterSystemMaps: ModuleConfigModel = {
  moduleName: 'Data Adapter System Maps',
  layout: 'DEFAULT',
  path: 'dataAdapterSystemMaps',
  renderComponent: ApiCaller,
  postSampleData: {
    sourceSystemCode: '',
    sourceSystemModuleCode: '',
    targetSystemCode: '',
    targetSystemModuleCode: '',
    operationTriggers: [
      {
        sourceOperationCode: 'CREATED|UPDATED',
        type: 'ASYNCTRANSACTIONAL|SYNCTRANSACTIONAL|LONGRUNNING',
        longTaskEvalTimeout: '',
        longTaskEvalScriptContent: 'javscript function for evaluating how the task result is considered as done',
        operationTriggerFlowId: '',
        sourceOperationCondition: 'JSON triggering condition, example { data: { resultType: "BRIXYIELDCALCULATION", ... }, ... }',
        sourceIdPath: 'json path to be selected source data key (default value is "_id" field)',
      },
    ],
  },
  post: {
    showStringEscape: true,
    url: API_DOMAIN + '/dataAdapterSystemMaps',
  },
  patch: {
    url: API_DOMAIN + '/dataAdapterSystemMaps',
  },
  get: {
    url: API_DOMAIN + '/dataAdapterSystemMaps',
    fields: [
      {
        field: 'sourceSystemCode',
        name: 'Source System',
      },
      {
        field: 'sourceSystemModuleCode',
        name: 'Source System Module',
      },
      {
        field: 'targetSystemCode',
        name: 'Target System',
      },
      {
        field: 'targetSystemModuleCode',
        name: 'Target System Module',
      },
      {
        field: 'operationTriggers',
        name: 'Triggers',
      },
    ],
  },
};
