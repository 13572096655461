
import axios from 'axios';
import beautify from 'json-beautify';

import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { ModuleConfigModel } from '../configs/ApiConfig';
import { NODE_ENV } from '../systems/configurations';

@Component({})
export default class Synchronizer extends Vue {
  //#region authenticate
  async getAccessToken() {
    let accessToken = null;
    if (NODE_ENV !== 'development') {
      try {
        accessToken = await this.$auth.getTokenSilently();
      } catch (err) {
        this.$notification['error']({
          message: err.message,
          description: '',
          placement: 'bottomRight',
        });
      }
    }

    return accessToken;
  }
  //#endregion

  loading: boolean = false;

  query: string = '';
  body: string = '';

  @Prop()
  selectedModule!: ModuleConfigModel;

  @Prop()
  queryObject: any;

  @Watch('queryObject')
  onSelectedRowDataChanged(val: any) {
    console.log(val);
    this.query = `systemCode=${val.sourceSystemCode}&systemModuleCode=${val.sourceSystemModuleCode}`;
  }

  @Prop()
  callBack!: Function;

  formatJSON(jsonString: string) {
    let json = JSON.parse(jsonString);
    return beautify(json, null, 4, 80);
  }

  onBodyBlur() {
    this.body = this.formatJSON(this.body);
  }

  async callSync() {
    if (this.selectedModule.post) {
      const accessToken = await this.getAccessToken();
      try {
        this.loading = true;
        axios
          .post(
            `${this.selectedModule.post.url}${this.query ? `?` : ``}${this.query}`,
            JSON.parse(this.body),
            accessToken
              ? {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              : undefined
          )
          .then((res) => {
            this.$notification['success']({
              message: 'Synced data successfully',
              description: '',
              placement: 'bottomRight',
            });
          })
          .catch((err) => {
            this.$notification['error']({
              message: 'Syncing data failed',
              description: err.response.data.errorMessage ?? err.message,
              placement: 'bottomRight',
            });
          })
          .finally(() => {
            this.callBack();
            this.loading = false;
          });
      } catch (err) {
        this.loading = false;
      }
    }
  }
}
