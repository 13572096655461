import ApiCaller from '../components/ApiCaller.vue';
import { API_DOMAIN } from '../systems/configurations';
import { ModuleConfigModel } from './interfaces/IApiConfig';

export const SystemModules: ModuleConfigModel = {
  moduleName: 'System Modules',
  layout: 'DEFAULT',
  path: 'systemModules',
  renderComponent: ApiCaller,
  postSampleData: {
    code: '',
    systemCode: '',
    name: '',
  },
  post: {
    url: API_DOMAIN + '/systemModules',
  },
  get: {
    url: API_DOMAIN + '/systemModules',
    fields: [
      {
        field: 'code',
        name: 'Code',
      },
      {
        field: 'systemCode',
        name: 'System Code',
      },
      {
        field: 'name',
        name: 'Name',
      },
    ],
  },
};
